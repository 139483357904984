import { useRef, useCallback, useState } from 'react';

import ActionButton from '../actionbutton/ActionButton';
import LinkButton from '../linkbutton/LinkButton';

import ArrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left-gray.svg';

import classes from './CartCarouselSwiper.module.scss';

import { Swiper } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.scss';

import useWindowSize from 'hooks/use-windowSize';

const CartCarouselSwiper = ({ children, section, origin, list }) => {
	const containerRef = useRef();

	const { desktopDevice, mobileDevice } = useWindowSize();

	const scroll = (scrollOffset) => {
		containerRef.current.scrollLeft += scrollOffset;
	};

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	const [viewAllLink] = useState(() => {
		if (section.custom) {
			return `/show-more-products/${origin}/${section.show_more_id}?ordering=${
				section.ordering ?? ''
			}&content=${section.type}${section.filters ? `&filters=${section.filters}` : ''}`;
		} else {
			let link = '/explore';
			let ordering = '';
			let filters = '';
			if (section.ordering) {
				ordering = `ordering=${section.ordering}`;
			}
			if (section.filters && Object.keys(section.filters).length > 0) {
				filters = Object.keys(section.filters)
					.map((key) => `${key}=${section.filters[key]}`)
					.join('&');
			}
			if (!!ordering || !!filters) {
				link = link + '?' + [ordering, filters].join('&');
			}
			return link
		}
	}, []);

	return (
		<section
			className={`${classes['cart-carousel']} d-flex flex-column ${
				origin === 'my-uni' ? '' : 'container'
			}`}>
			<div className={`${classes['cart-carousl-headline']} d-flex flex-column gap-2`}>
				<h3>{section.headline}</h3>
				<p>{section.description}</p>
			</div>
			<div
				className={`${classes['cart-carousel__header']} d-flex flex-row justify-content-between align-items-center`}>
				<header>
					<h2>{section.title}</h2>
				</header>
				<div className="d-flex flex-row align-items-center justify-content-center gap-3">
					<div className="d-none d-xl-flex flex-row align-items-center gap-3">
						<>
							<ActionButton kind="blank" onClick={handlePrev} data-testid="prev-button">
								<ArrowLeftIcon />
							</ActionButton>
							<ActionButton kind="blank" onClick={handleNext} data-testid="next-button">
								<ArrowRightIcon />
							</ActionButton>
						</>
					</div>
					{section.does_show_more && (
						<LinkButton className={'d-none d-md-block'} kind="simple" to={viewAllLink}>
							View All
						</LinkButton>
					)}
				</div>
			</div>
			{/* <article
				ref={containerRef}
				className={`${classes['cart-carousel__contents']} d-flex flex-row `}> */}
			<div>
				<Swiper
					ref={sliderRef}
					slidesPerView={desktopDevice ? 3.1 : mobileDevice ? 1.1 : 2.1}
					spaceBetween={30}
					className="mySwiper">
					{children}
				</Swiper>
			</div>
			{/* {children}
			</article> */}
			{section.does_show_more && (
				<LinkButton
					className={'d-block d-md-none'}
					kind="simple"
					// to={`/show-more-products/${origin}/${section.show_more_id}?ordering=${
					// 	section.ordering ?? ''
					// }&content=${section.type}`}
					to={viewAllLink}
					>
					View All
				</LinkButton>
			)}
		</section>
	);
};

export default CartCarouselSwiper;
